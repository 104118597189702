import CarouselItem from './CarouselItem'
import {IsMobile} from '../mediaQuery'
type Props = {
    title?: string
    description?: string
    link?: string
    items?: any
}
export default function Carousel(props: Props): React.ReactElement {
    return (
        IsMobile() ?
        <>
            <div className="carousel carousel-center bg-white rounded-box w-full space-x-8 p-8">
                {props.items.map((d: any) => {
                    // console.log(d)
                    return <CarouselItem key = {d.id} contents={d}></CarouselItem>
                })}
            </div>
        </>
        :<>
        <div className="carousel carousel-center bg-white rounded-box w-full gap-8 p-8 flex-wrap">
            {props.items.map((d: any) => {
                // console.log(d)
                return <CarouselItem key ={d.id} contents={d}></CarouselItem>
            })}
        </div>
    </>
    )
}
