import Carousel from '../Carousel/Carousel'
import Card from '../cards/Card'
import {IsMobile} from '../mediaQuery'

type Props = {
    year: string
    title: string
    description: string
    left?: boolean
    link?: string
    yearRight?: string
    titleRight?: string
    descriptionRight?: string
    items: any
}
export default function Item(props: Props): React.ReactElement {
    const left = 'timeline-start mb-10 md:text-end mr-3'
    const right = 'timeline-end  w-[80dvw] mb-auto mt-[0.25rem]'
    // console.log(props.items)
    return (
        <li
            style={{
                gridTemplateColumns: IsMobile() ? '1.5rem' : '12rem'
            }}>
            <div className="timeline-middle">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5">
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            {props.yearRight && (
                <div
                    className={IsMobile() ? right : left}
                    style={
                        IsMobile() ? {gridRowEnd: 1, textAlign: 'left'} : {gridRowEnd: 1}
                    }>
                    <time className="font-mono italic">{props.yearRight}</time>
                    <div className="text-lg font-black">{props.titleRight}</div>
                    {props.descriptionRight}
                </div>
            )}

            <div
                className={props.left ? left : right}
                style={IsMobile() && props.yearRight ? {marginTop: '5.5rem'} : {}}>
                <time className="font-mono italic">{props.year}</time>
                <div className="text-lg font-black">{props.title}</div>
                {props.description}
                <div>
                    <Carousel items={props.items}></Carousel>
                </div>
            </div>
            <hr />
        </li>
    )
}
