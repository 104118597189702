import {useEffect, useState} from 'react'
import Badges from '../badge/Badges'
import {IsMobile} from '../mediaQuery'

type Props = {contents?: any}
export default function Card(props: Props): React.ReactElement {
    const [mobile, setMobile] = useState(IsMobile())

    return (
        <>
            {props.contents && (
                <div className="card bg-base-100 w-96 shadow-md">
                    <figure>
                        {props.contents.imgSrc && (
                            <img
                                className={
                                    mobile
                                        ? 'object-cover max-h-[30vw] w-[105%]'
                                        : 'object-cover max-h-[210px] w-[105%]'
                                }
                                src={'/assets/thumbnails/works/' + props.contents.imgSrc}
                                alt={props.contents.title_kor}
                            />
                        )}
                    </figure>
                    <div className="card-body ">
                        <h2
                            className="card-title line-clamp-1 hover:line-clamp-4"
                            style={
                                mobile
                                    ? {
                                          fontSize: '1rem'
                                      }
                                    : {fontSize: '1.25rem'}
                            }>
                            {props.contents.title_kor}
                        </h2>
                        {props.contents.tech && (
                            <Badges tech={props.contents.tech}></Badges>
                        )}
                        <div>
                            {Array.isArray(props.contents.date) ? (
                                props.contents.date.map((s: string) => {
                                    return (
                                        <p key={s.replace(' ', '')}
                                            className={
                                                mobile
                                                    ? 'text-[0.75rem]'
                                                    : 'text-[0.85rem]'
                                            }>
                                            {s}
                                        </p>
                                    )
                                })
                            ) : (
                                <p
                                    className={
                                        mobile ? 'text-[0.75rem]' : 'text-[0.85rem]'
                                    }>
                                    {props.contents.date}
                                </p>
                            )}
                        </div>
                        <ul
                            className="list-disc"
                            style={
                                mobile
                                    ? {
                                          fontSize: '0.8rem',
                                          flexGrow: 3
                                      }
                                    : {
                                          fontSize: '1rem',
                                          flexGrow: 3
                                      }
                            }>
                            {Array.isArray(props.contents.description) ? (
                                props.contents.description.map((s: string) => {
                                    return <li  key={s.replace(' ', '')} >{s}</li>
                                })
                            ) : (
                                <span>{props.contents.description}</span>
                            )}
                        </ul>
                        {/* Temporarily unavailable
                        <div className="card-actions justify-end">
                            <button disabled className="btn btn-primary m-auto">
                                자세히 보기
                            </button>
                        </div>
                         */}
                    </div>
                </div>
            )}
        </>
    )
}
