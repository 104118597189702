import {data} from '../../data/badge'

type Props = {
    tech: string[]
}

export default function Badges(props: Props): React.ReactElement {
    return (
        <div className="flex gap-0 row flex-wrap">
            {props.tech.map(d => {
                const source = data.find(({key}) => key == d)
                return <img key = {d} className="h-4" src={source ? source.value : ''}></img>
            })}
        </div>
    )
}
