import Card from '../cards/Card'

type Props = {
    contents?: any
}
export default function CarouselItem(props: Props): React.ReactElement {
    // console.log(props.contents)
    return (
        <div className="carousel-item max-w-[60vw]">
            <Card key = {props.contents.id} contents={props.contents}></Card>
        </div>
    )
}
