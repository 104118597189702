import {useMediaQuery} from 'react-responsive'

export const IsMobile = () => {
    const isMobile = useMediaQuery({maxWidth: 767})
    return isMobile
}
export const Mobile = ({children}: {children: JSX.Element}): JSX.Element | null => {
    const isMobile = useMediaQuery({maxWidth: 767})
    return isMobile ? children : null
}

export const Desktop = ({children}: {children: JSX.Element}): JSX.Element | null => {
    const isDesktop = useMediaQuery({minWidth: 768})
    return isDesktop ? children : null
}

export const Default = ({children}: {children: JSX.Element}): JSX.Element | null => {
    const isDesktop = useMediaQuery({minWidth: 768})
    const defaultClass = isDesktop
        ? 'flex flex-col gap-0 p-0 pb-0 pt-6 m-auto bg-white w-[1280px] h-dvh min-h-[800px] overflow-y-hidden overflow-x-auto'
        : 'flex flex-col items-center justify-center w-full min-h-dvh gap-3 pt-[76px] pb-10 justify-items-center'

    return <div className={defaultClass}>{children}</div>
}
