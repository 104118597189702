import React from 'react'
import logo from './logo.svg'
import './App.css'
import Card from './components/cards/Card'
import Timeline from './components/timeline/Timeline'
import NavBar from './components/navBar/navBar'
import Modal from './components/modal/Modal'
function App() {
    return (
        <>
            <div className="App">
                <NavBar></NavBar>
                <Timeline></Timeline>
                <Modal></Modal>
            </div>
        </>
    )
}

export default App
