export const data: {key: string; value: string}[] = [
    {
        key: 'javascript',
        value: 'https://img.shields.io/badge/javascript-%23323330.svg?style=for-the-badge&logo=javascript&logoColor=%23F7DF1E'
    },
    {
        key: 'html5',
        value: 'https://img.shields.io/badge/html5-%23E34F26.svg?style=for-the-badge&logo=html5&logoColor=white'
    },
    {
        key: 'swift',
        value: 'https://img.shields.io/badge/swift-F54A2A?style=for-the-badge&logo=swift&logoColor=white'
    },
    {
        key: 'css',
        value: 'https://img.shields.io/badge/css3-%231572B6.svg?style=for-the-badge&logo=css3&logoColor=white'
    },
    {
        key: 'tailwindCSS',
        value: 'https://img.shields.io/badge/tailwindcss-%2338B2AC.svg?style=for-the-badge&logo=tailwind-css&logoColor=white'
    },
    {
        key: 'react',
        value: 'https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB'
    },
    {
        key: 'arduino',
        value: 'https://img.shields.io/badge/-Arduino-00979D?style=for-the-badge&logo=Arduino&logoColor=white'
    },
    {
        key: 'python',
        value: 'https://img.shields.io/badge/python-3670A0?style=for-the-badge&logo=python&logoColor=ffdd54'
    },
    {
        key: 'raspberrypi',
        value: 'https://img.shields.io/badge/-RaspberryPi-C51A4A?style=for-the-badge&logo=Raspberry-Pi'
    },
    {
        key: 'nodejs',
        value: 'https://img.shields.io/badge/node.js-6DA55F?style=for-the-badge&logo=node.js&logoColor=white'
    },
    {
        key: 'firebase',
        value: 'https://img.shields.io/badge/firebase-a08021?style=for-the-badge&logo=firebase&logoColor=ffcd34'
    },
    {
        key: 'gcp',
        value: 'https://img.shields.io/badge/GoogleCloud-%234285F4.svg?style=for-the-badge&logo=google-cloud&logoColor=white'
    },
    {
        key: 'kotlin',
        value: 'https://img.shields.io/badge/kotlin-%237F52FF.svg?style=for-the-badge&logo=kotlin&logoColor=white'
    },
    {
        key: 'mysql',
        value: 'https://img.shields.io/badge/mysql-4479A1.svg?style=for-the-badge&logo=mysql&logoColor=white'
    },
    {
        key: 'mosquitto',
        value: 'https://img.shields.io/badge/mosquitto-%233C5280.svg?style=for-the-badge&logo=eclipsemosquitto&logoColor=white'
    },
    {
        key: 'c',
        value: 'https://img.shields.io/badge/c-%2300599C.svg?style=for-the-badge&logo=c&logoColor=white'
    },
    {
        key: 'csharp',
        value: 'https://img.shields.io/badge/c%23-%23239120.svg?style=for-the-badge&logo=csharp&logoColor=white'
    },
    {
        key: 'cpp',
        value: 'https://img.shields.io/badge/c++-%2300599C.svg?style=for-the-badge&logo=c%2B%2B&logoColor=white'
    },
    {
        key: 'mariadb',
        value: 'https://img.shields.io/badge/MariaDB-003545?style=for-the-badge&logo=mariadb&logoColor=white'
    },
    {
        key: 'typescript',
        value: 'https://img.shields.io/badge/typescript-%23007ACC.svg?style=for-the-badge&logo=typescript&logoColor=white'
    },
    {
        key: 'tailwind',
        value: 'https://img.shields.io/badge/tailwindcss-%2338B2AC.svg?style=for-the-badge&logo=tailwind-css&logoColor=white'
    }
]
