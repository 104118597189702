type Props = {
    title?: string
}
export default function NavBar(props: Props): React.ReactElement {
    return (
        <div className="navbar bg-base-100 z-10 fixed top-0 left-0">
            <div className="flex-1">
            {/* Temporarily Unavailable
                <a className="btn btn-ghost text-xl">PORTFOLIO</a>*/}
                <h1 className="mx-[1rem] height-[3rem] font-bold text-xl">PORTFOLIO</h1>
            </div>
            <div className="flex-none">
                 {/* Temporarily Unavailable
                <ul className="menu menu-horizontal px-1">
                    <li>
                        <a
                            onClick={() =>
                                (
                                    document.getElementById(
                                        'underConstruction'
                                    ) as HTMLDialogElement
                                )?.showModal()
                            }>
                            Education
                        </a>
                    </li>
                    <li>
                        <details>
                            <summary>More</summary>
                            <ul className="bg-base-100 rounded-t-none p-2">
                                <li>
                                    <a
                                        onClick={() =>
                                            (
                                                document.getElementById(
                                                    'underConstruction'
                                                ) as HTMLDialogElement
                                            )?.showModal()
                                        }>
                                        Github
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() =>
                                            (
                                                document.getElementById(
                                                    'underConstruction'
                                                ) as HTMLDialogElement
                                            )?.showModal()
                                        }>
                                        LinkedIn
                                    </a>
                                </li>
                            </ul>
                        </details>
                    </li>
                </ul>
                */}
            </div>
        </div>
    )
}
