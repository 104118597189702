export const contents = [
    {
        '2024': [
            {
                id: 'registration',
                year: 2024,
                date: '2023.11 - present',
                tech: ['react', 'firebase', 'nodejs', 'gcp'],
                title_kor: '(주)제이쎄노 대회 접수 웹',
                title_eng: 'Race Registration System',
                category: 'works',
                imgSrc: 'register_thumb.PNG',
                description: [
                    '풀스택 웹 개발',
                    '총 가입자 약 8000명',
                    '총 2억원 이상의 결제와 7000건 이상의 결제 트랜잭션 처리'
                ]
            },
            {
                id: 'tantan',
                year: 2024,
                date: [
                    '2024.08 - 2024.09 (기능 추가)',
                    '2023.08 - 2023.10 (기능 추가)',
                    '2022.07 - 2022.08 (개발)'
                ],
                tech: ['html5', 'css', 'javascript', 'firebase', 'nodejs'],
                title_kor: '탄탄한 남해로 오시다 개발 용역',
                title_eng: 'TanTanNamhae Project',
                category: 'works',
                imgSrc: 'tantan_thumb.png',
                description: ['3년간 안정적인 운영 및 기능 확장']
            },
            {
                id: 'smartlive',
                year: 2024,
                date: '2024.08 - 2024.10',
                tech: ['react', 'typescript', 'tailwind'],
                title_kor: '스마트라이브 웹',
                title_eng: 'Smartlive',
                category: 'works',
                imgSrc: 'smartlive_thumb.png',
                description: ['프론트엔드 개발', 'GPX 파일 처리', 'VISX 라이브러리 사용']
            }
        ],
        2023: [
            {
                id: 'dcm',
                year: 2023,
                date: 2023.09,
                tech: ['kotlin', 'firebase'],
                title_kor: '디지털 코스 마킹용 Android 앱',
                title_eng: 'Digital Course Marking Android APP',
                category: 'works',
                imgSrc: 'dcm_android.png',
                description: ''
            },
            {
                id: 'j-logis',
                year: 2023,
                date: '2023.08 - 2023.10',
                tech: ['react', 'firebase', 'nodejs', 'gcp'],
                title_kor: '드롭백 위치 확인용 웹',
                title_eng: '',
                category: 'works',
                imgSrc: 'j-logis.jpg',
                description: ''
            },
            {
                id: 'j-logis-souv',
                year: 2023,
                date: '2023.09 - 2023.10',
                tech: ['html5', 'css', 'javascript', 'firebase'],
                title_kor: '대회 기념품 관리용 웹',
                title_eng: '',
                category: 'works',
                imgSrc: 'souv.png',
                description: ''
            }
        ],
        2022: [
            {
                id: 'peakerdise',
                year: 2022,
                date: '2021 - 2022',
                tech: ['html5', 'css', 'javascript', 'firebase'],
                title_kor: '울주 피커다이스 웹',
                title_eng: 'Ulju Peakerdise',
                category: 'works',
                imgSrc: 'post_peakerdise.png',
                description: ''
            },
            {
                id: 'dcm',
                year: 2022,
                date: '2022.07 - 2022.12',
                tech: ['swift', 'firebase'],
                title_kor: '디지털 코스 마킹용 iOS 앱',
                title_eng: 'Digital Course Marking System',
                category: 'works',
                imgSrc: 'dcm_android.png',
                description: ''
            },
            /* Temporarily Unavailable
            {
                id: 'rnd_3',
                year: 2022,
                date: '2021-2022',
                tech: '',
                title_kor:
                    '아웃도어 실시간 레이스의 메타버스 연동 비대면 대회운영 플랫폼 개발',
                title_eng:
                    'Development of a Virtual Race Management Platform with Metaverse Integration for Real-Time Outdoor Races',
                category: 'works',
                imgSrc: '',
                description: ''
            }*/
        ],
        2021: [
            {
                id: 'jsv',
                year: 2021,
                date: '2020.07 - 2021.06',
                tech: ['nodejs', 'csharp', 'c', 'mariadb', 'mosquitto'],
                title_kor: '제주도 스마트 빌리지 - 해녀 안전 모니터링 시스템',
                title_eng:
                    'Jeju Smart Village Haenyeo safety monitoring system development',
                category: 'works',
                imgSrc: 'sumbi.jpg',
                description: ''
            },
            {
                id: 'virtual',
                year: 2021,
                date: '2021.04 - 2021.06',
                tech: ['html5', 'css', 'javascript', 'firebase', 'nodejs', 'mysql'],
                title_kor: '2021 KOREA 50K 버추얼 레이스 웹',
                title_eng: '2021 KOREA 50K VIRTUAL RACE SYSTEM',
                category: 'works',
                imgSrc: 'virtual.gif',
                description: ''
            },
            {
                id: 'muan',
                year: 2021,
                date: '2020.12 - 2021.05',
                tech: ['c', 'mosquitto'],
                title_kor: '무안군 스마트 빌리지 환경 센서',
                title_eng:
                    'Muan Smart Village Environmental sensor data collection device development',
                category: 'works',
                imgSrc: 'post_2_1.jpg',
                description: ''
            }
        ],
        2020: [
            {
                id: 'hightrail',
                year: 2020,
                date: '2020.09 - 2020.10',
                tech: ['html5', 'css', 'javascript', 'firebase'],
                title_kor: 'HIGHTRAIL NINE PEAKS ULJU 인증 웹',
                title_eng:
                    'HIGHTRAIL NINE PEAKS ULJU Certification System for Summit Verification System',
                category: 'works',
                imgSrc: 'post_5.png',
                description: ''
            },
            {
                id: 'citytrail',
                year: 2020,
                date: '2020.03 - 2021.05',
                tech: ['html5', 'css', 'javascript', 'firebase'],
                title_kor: '시티트레일 인증 웹',
                title_eng: 'CITYTRAIL',
                category: 'works',
                imgSrc: 'citytrail.gif',
                description: ''
            },
            {
                id: 'firedetection',
                year: 2020,
                date: '2020.07 - 2021.01',
                tech: ['c'],
                title_kor: '화재 감지 연동 시스템',
                title_eng: 'Fire Detection Parser',
                category: 'works',
                imgSrc: 'post_4.jpg',
                description: ''
            },
            /* Temporarily Unavailable
            {
                id: 'rnd_1',
                year: 2020,
                date: 2020,
                tech: ['html5', 'css', 'javascript', 'firebase'],
                title_kor: '트레일러닝 버추얼레이스를 위한 트랙맵 공유 플랫폼 개발',
                title_eng:
                    'Development of a Track Map Sharing Platform for Virtual Trail Running Races',
                category: 'works',
                imgSrc: '',
                description: ''
            },
            {
                id: 'rnd_2',
                year: 2020,
                date: 2020,
                tech: ['html5', 'css', 'javascript', 'firebase'],
                title_kor:
                    'CP디바이스 통신 연동 모바일 기반 아웃도어 레이스 운영 시스템 개발',
                title_eng:
                    'Development of a Mobile-Based Outdoor Race Management System with CP Device Communication Integration',
                category: 'works',
                imgSrc: '',
                description: ''
            } */
        ]
    }
]
