import icon from '../../assets/thumbnails/constructionIcon.png'

type Props = {
    title?: string
}
export default function Modal(props: Props): React.ReactElement {
    return (
        <>
            <dialog id="underConstruction" className="modal">
                <div className="modal-box text-center">
                    <img className="m-auto w-20" src={icon} />
                    <p className="py-4 pb-0">공사 중</p>
                    <div className="modal-action items-center justify-center">
                        <form method="dialog m-auto">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn">닫기</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </>
    )
}
