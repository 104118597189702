import Item from './Item'
import ItemWithCards from './ItemWithCards'
import {contents} from '../../data/contents'

type Props = {
    link?: string
}
export default function Timeline(props: Props): React.ReactElement {
    return (
        <div className="mt-[5rem] flex flex-col">
            {/* Temporarily Unavailable
            <select
                disabled
                className="select select-bordered  w-[15rem] self-end mr-[5vw] max-w-[80dvw] ">
                <option disabled selected hidden>
                    Sort By
                </option>
                <option>Date</option>
                <option>Type</option>
            </select>*/}
            <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
                {/*
                <ItemWithCards
                    left={false}
                    year=""
                    title="주요 프로젝트"
                    description="넣을까말까..."></ItemWithCards>
                */}
                <ItemWithCards
                    left={false}
                    year=""
                    title=""
                    description="2024"
                    items={contents[0][2024]}></ItemWithCards>
                <ItemWithCards
                    year="2023"
                    title=""
                    yearRight="2020.01 - 2024.02"
                    titleRight="(주)제이쎄노"
                    descriptionRight="Software Developer"
                    description=""
                    items={contents[0][2023]}></ItemWithCards>
                <ItemWithCards
                    left={false}
                    year="2022"
                    title=""
                    description=""
                    items={contents[0][2022]}></ItemWithCards>
                <ItemWithCards
                    left={false}
                    year="2021"
                    title=""
                    description=""
                    items={contents[0][2021]}></ItemWithCards>
                <ItemWithCards
                    left={false}
                    year="2020"
                    title=""
                    description=""
                    items={contents[0][2020]}></ItemWithCards>
            </ul>
        </div>
    )
}
